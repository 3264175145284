import React from 'react';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import RelayEnvironment from './RelayEnvironment';
import Routing from './Routing';

export default function Relay() {
  return (
      <RelayEnvironmentProvider environment={ RelayEnvironment }>
          <Routing />
      </RelayEnvironmentProvider>
  );
}
