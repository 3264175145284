/**
 * @generated SignedSource<<8143c169341e44a381a8eaeeda3b7f86>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type PackInput = {|
  metadata?: ?MetadataInput,
  pockets?: ?$ReadOnlyArray<?PocketInput>,
|};
export type MetadataInput = {|
  defaultUnits?: ?string,
  id?: ?string,
  name?: ?string,
  date?: ?string,
  location?: ?LocationInput,
|};
export type LocationInput = {|
  link?: ?string,
  name?: ?string,
|};
export type PocketInput = {|
  id?: ?string,
  name?: ?string,
  items?: ?$ReadOnlyArray<?ItemInput>,
|};
export type ItemInput = {|
  price?: ?number,
  weight?: ?number,
  units?: ?string,
  id?: ?string,
  name?: ?string,
  link?: ?string,
  description?: ?string,
  amount?: ?number,
|};
export type PacksPagesBuildPackQuery$variables = {|
  pack?: ?PackInput,
  jwt: string,
|};
export type PacksPagesBuildPackQuery$data = {|
  +buildPack: ?{|
    +metadata: ?{|
      +id: ?string,
    |},
  |},
|};
export type PacksPagesBuildPackQuery = {|
  variables: PacksPagesBuildPackQuery$variables,
  response: PacksPagesBuildPackQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jwt"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pack"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "jwt",
        "variableName": "jwt"
      },
      {
        "kind": "Variable",
        "name": "pack",
        "variableName": "pack"
      }
    ],
    "concreteType": "Pack",
    "kind": "LinkedField",
    "name": "buildPack",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Metadata",
        "kind": "LinkedField",
        "name": "metadata",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PacksPagesBuildPackQuery",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PacksPagesBuildPackQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "771de428eeea3266f215fbec31622699",
    "id": null,
    "metadata": {},
    "name": "PacksPagesBuildPackQuery",
    "operationKind": "mutation",
    "text": "mutation PacksPagesBuildPackQuery(\n  $pack: PackInput\n  $jwt: String!\n) {\n  buildPack(pack: $pack, jwt: $jwt) {\n    metadata {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "df87e97d38fcf86c63389fd990e8ee84";

module.exports = ((node/*: any*/)/*: Mutation<
  PacksPagesBuildPackQuery$variables,
  PacksPagesBuildPackQuery$data,
>*/);
