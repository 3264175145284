import { useState, useEffect } from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { BsGoogle } from 'react-icons/bs';
import { getAuth, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const passwordRegex = /^.{8,}$/;

export default function SignUpPage() {

    const auth = getAuth()
    const navigate = useNavigate()    

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [passwordsMatch, setPasswordsMatch] = useState(false)
    const [message, setMessage] = useState("")

    const handleSetEmail = (e) => {
        const updated = e.target.value
        setEmail(updated)
        if (updated.length > 0 && !emailRegex.test(updated)) setMessage("Invalid email address")
        else setMessage("")
    }

    const handleSetPassword = (e) => {
        const updated = e.target.value
        setPassword(updated)
        if (updated.length > 0 && !passwordRegex.test(updated)) setMessage("Password must be at least 8 characters")
        else if (updated.length > 0 && confirmPassword.length > 0 && updated !== confirmPassword) setMessage("Passwords do not match")
        else setMessage("")
    }

    const handleSetConfirmPassword = (e) => {
        const updated = e.target.value
        setConfirmPassword(updated)
        if (password.length > 0 && !passwordRegex.test(password)) setMessage("Password must be at least 8 characters")
        else if (password.length > 0 && updated.length > 0 && updated !== password) setMessage("Passwords do not match")
        else setMessage("")
    }

    useEffect(() => {
        if (password.length > 0 && passwordRegex.test(password) && password === confirmPassword) setPasswordsMatch(true)
        else setPasswordsMatch(false)
    }, [password, confirmPassword, setPasswordsMatch])

    const handleSignUp = () => {
        if (!emailRegex.test(email)) return
        else if (!passwordRegex.test(password) || password !== confirmPassword) return
        createUserWithEmailAndPassword(auth, email, password).then(() => navigate("/packs"))
        .catch(() => setMessage("We could not create account."))
    }

    const handleGoogleSignUp = () => {
        const provider = new GoogleAuthProvider()
        signInWithPopup(auth, provider).then(() => navigate("/packs"))
        .catch(() => setMessage("Failed to authenticate with Google."))
    }

    useEffect(() => auth.onAuthStateChanged(async user => user && navigate("/packs"), [auth, navigate]))
    
    return (
        <div className="flex flex-col items-center m-8 md:p-12">
            <div className="w-full max-w-2xl flex flex-col items-center">
                <Navbar />
                <span className="fade-in w-full flex flex-col items-center">
                    <div className="w-72 flex flex-col items-center">
                        <p className="text-2xl font-bold mt-12">Sign Up</p>
                        <input className="w-full h-12 mt-2 bg-neutral-200 box-border p-4 text-neutral-700 focus:outline-neutral-700 font-bold text-base" placeholder="Email" type="text" value={ email } onChange={ handleSetEmail } />
                        <input className={ `w-full h-12 mt-2 bg-neutral-200 box-border p-4 text-neutral-700 ${ passwordsMatch ? "focus:outline-green-400" : "focus:outline-neutral-700" } font-bold text-base` } placeholder="Password" type="password"value={ password } onChange={ handleSetPassword } />
                        <input className={ `w-full h-12 mt-2 bg-neutral-200 box-border p-4 text-neutral-700 ${ passwordsMatch ? "focus:outline-green-400" : "focus:outline-neutral-700" } font-bold text-base` } placeholder="Confirm Password" type="password" value={ confirmPassword } onChange={ handleSetConfirmPassword } />
                        <p className="w-full mt-2 text-xs font-extralight italic">
                            By signing up you are agreeing to ultralitepack <Link className="text-blue-500" to={"/terms"}>terms and conditions</Link>.
                        </p>
                        <div className="w-full flex justify-between items-center mt-1">
                            <p className="w-44 font-thin text-sm text-neutral-700">{message}</p>
                            <button className="bg-neutral-700 text-neutral-100 text-xl px-4 py-2" onClick={ handleSignUp }>Sign Up</button>
                        </div>
                    </div>
                    <div className="w-72 mt-12 mb-10">
                        <p className="w-full text-center border-b leading-[.1rem] border-neutral-700">
                            <span className="px-2 bg-neutral-100 font-bold">Or</span>
                        </p>
                    </div>
                    <div className="w-72">
                        <button className="w-full h-12 mt-2 bg-[#4285F4] box-border p-4 text-neutral-100 font-bold text-base flex justify-center items-center" onClick={ handleGoogleSignUp }>
                            <BsGoogle className="mr-2"/>
                            <p>Sign Up With Google</p>
                        </button>
                    </div>
                </span>
            </div>
            <div className="absolute bottom-8 md:bottom-12">
                <Footer />
            </div>
        </div>
    )
}
