/**
 * @generated SignedSource<<e75a0c26352b98d31747b12d2c0dd3da>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type MetadataInput = {|
  defaultUnits?: ?string,
  id?: ?string,
  name?: ?string,
  date?: ?string,
  location?: ?LocationInput,
|};
export type LocationInput = {|
  link?: ?string,
  name?: ?string,
|};
export type EditPackPageUpdateMetadataMutation$variables = {|
  metadata: MetadataInput,
  jwt: string,
|};
export type EditPackPageUpdateMetadataMutation$data = {|
  +updateMetadata: ?{|
    +id: ?string,
    +createdAt: ?number,
    +name: ?string,
    +author: ?{|
      +id: ?string,
    |},
    +date: ?string,
    +location: ?{|
      +name: ?string,
      +link: ?string,
    |},
    +defaultUnits: ?string,
  |},
|};
export type EditPackPageUpdateMetadataMutation = {|
  variables: EditPackPageUpdateMetadataMutation$variables,
  response: EditPackPageUpdateMetadataMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jwt"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "metadata"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "jwt",
        "variableName": "jwt"
      },
      {
        "kind": "Variable",
        "name": "metadata",
        "variableName": "metadata"
      }
    ],
    "concreteType": "Metadata",
    "kind": "LinkedField",
    "name": "updateMetadata",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Author",
        "kind": "LinkedField",
        "name": "author",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Location",
        "kind": "LinkedField",
        "name": "location",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "link",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "defaultUnits",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditPackPageUpdateMetadataMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditPackPageUpdateMetadataMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "0b5230a086b280394bdb7439eca112a8",
    "id": null,
    "metadata": {},
    "name": "EditPackPageUpdateMetadataMutation",
    "operationKind": "mutation",
    "text": "mutation EditPackPageUpdateMetadataMutation(\n  $metadata: MetadataInput!\n  $jwt: String!\n) {\n  updateMetadata(metadata: $metadata, jwt: $jwt) {\n    id\n    createdAt\n    name\n    author {\n      id\n    }\n    date\n    location {\n      name\n      link\n    }\n    defaultUnits\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "9216f5091126872fbb8d8c22eba18a5a";

module.exports = ((node/*: any*/)/*: Mutation<
  EditPackPageUpdateMetadataMutation$variables,
  EditPackPageUpdateMetadataMutation$data,
>*/);
