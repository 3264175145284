/**
 * @generated SignedSource<<87e242fc7aef6984ac3f30b2e202b24e>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type EditPackPageMyPackQuery$variables = {|
  id: string,
  jwt: string,
|};
export type EditPackPageMyPackQuery$data = {|
  +myPack: ?{|
    +metadata: ?{|
      +id: ?string,
      +createdAt: ?number,
      +name: ?string,
      +author: ?{|
        +id: ?string,
      |},
      +date: ?string,
      +location: ?{|
        +name: ?string,
        +link: ?string,
      |},
      +defaultUnits: ?string,
    |},
    +pockets: ?$ReadOnlyArray<?{|
      +id: ?string,
      +name: ?string,
      +items: ?$ReadOnlyArray<?{|
        +id: ?string,
        +name: ?string,
        +link: ?string,
        +description: ?string,
        +amount: ?number,
        +price: ?number,
        +weight: ?number,
        +units: ?string,
      |}>,
    |}>,
  |},
|};
export type EditPackPageMyPackQuery = {|
  variables: EditPackPageMyPackQuery$variables,
  response: EditPackPageMyPackQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "jwt"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "link",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "jwt",
        "variableName": "jwt"
      }
    ],
    "concreteType": "Pack",
    "kind": "LinkedField",
    "name": "myPack",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Metadata",
        "kind": "LinkedField",
        "name": "metadata",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Author",
            "kind": "LinkedField",
            "name": "author",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Location",
            "kind": "LinkedField",
            "name": "location",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "defaultUnits",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Pocket",
        "kind": "LinkedField",
        "name": "pockets",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Item",
            "kind": "LinkedField",
            "name": "items",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "weight",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "units",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditPackPageMyPackQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditPackPageMyPackQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "11c8aea9c2a35bc3ebc401cd339e7c8d",
    "id": null,
    "metadata": {},
    "name": "EditPackPageMyPackQuery",
    "operationKind": "query",
    "text": "query EditPackPageMyPackQuery(\n  $id: String!\n  $jwt: String!\n) {\n  myPack(id: $id, jwt: $jwt) {\n    metadata {\n      id\n      createdAt\n      name\n      author {\n        id\n      }\n      date\n      location {\n        name\n        link\n      }\n      defaultUnits\n    }\n    pockets {\n      id\n      name\n      items {\n        id\n        name\n        link\n        description\n        amount\n        price\n        weight\n        units\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "dedcb6a34f563dce763971b66563830f";

module.exports = ((node/*: any*/)/*: Query<
  EditPackPageMyPackQuery$variables,
  EditPackPageMyPackQuery$data,
>*/);
