import { useState, useEffect, Suspense } from "react";
import {useSelector} from "react-redux";
import { IoLocationSharp, IoLogoTwitter, IoLogoFacebook } from "react-icons/io5"
import { IoIosLink } from "react-icons/io"
import { Link, Navigate, useParams } from "react-router-dom";
import { Convert } from "./convert";
import DarkLogo from "../resources/logo_dark.png"
import { usePreloadedQuery, useQueryLoader } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import {ErrorBoundary} from 'react-error-boundary'

const PackQuery = graphql`
    query PackPageQuery($id: String!) {
        pack(id: $id) {
            metadata{id,createdAt,name,author{id},date,location{name,link},defaultUnits},
            pockets{id,name,items{id,name,link,description,amount,price,weight,units}}
        }
    }
`

export default function LoadedPackPage() {
    const { id } = useParams()

    const [queryRef, loadQuery] = useQueryLoader(PackQuery)
    useEffect(() => {
        loadQuery({ id: id }, {fetchPolicy: 'network-only'})
    }, [id, loadQuery])

    return (
        <ErrorBoundary fallbackRender={() => <Navigate to="/" />}>
            <Suspense fallback={<></>}>
                {
                    queryRef ? <PackPage id={id} queryRef={queryRef} /> : <></>
                }
            </Suspense>
        </ErrorBoundary>
    )
}

function PackPage({ id, queryRef }) {

    const user = useSelector(state => state.user)

    if (!queryRef) {
        throw new Error(`pack with ID ${id} not found`)
    }

    const { pack } = usePreloadedQuery(PackQuery, queryRef)

    const shareText = pack ? `Check out this pack - ${ pack.metadata.name } https://ultralitepack.com/pack/${ pack.metadata.id }` : ""

    const [units, setUnits] = useState(pack ? pack.metadata.defaultUnits : "oz")

    const [copied, setCopied] = useState(0);
    useEffect(() => {
        if (copied > 0) setTimeout(() => setCopied(copied-1), 1000);
    }, [copied]);

    return (
        <>
        {
            pack ? <>
                <div className="fade-in flex flex-col items-center m-8">
                    <div className="w-full max-w-2xl flex flex-col items-center">
                        <Suspense fallback={"loading..."}>
                            {/* Header */}
                            <div className="w-full">
                                <div className="w-full flex justify-between">
                                    <p className="overflow-hidden text-ellipsis flex-wrap w-full text-2xl font-bold bg-neutral-100 mr-2">{ pack.metadata.name }</p>
                                    <div className="flex items-center">
                                        <p className="text-2xl font-bold">{ ["lb", "kg", "oz"].includes(units) ? 
                                            pack.pockets.reduce((sum, pocket) => sum + pocket.items.reduce((sum, item) => sum + Convert(item.amount * item.weight, item.units, units), 0), 0).toFixed(2) : 
                                            pack.pockets.reduce((sum, pocket) => sum + pocket.items.reduce((sum, item) => sum + Convert(item.amount * item.weight, item.units, units), 0), 0).toFixed(0) }</p>
                                        <select className="font-bold bg-neutral-100 focus:outline-none h-full dropdown-input ml-1 px-1 w-10" name="units" id="units" defaultValue={ units } onChange={ (e) => setUnits(e.target.value) }>
                                            <option value="oz">oz</option>
                                            <option value="lb">lb</option>
                                            <option value="g">g</option>
                                            <option value="kg">kg</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="w-full flex justify-between flex-wrap items-center text-neutral-400">
                                    {
                                        pack.metadata.location.name && pack.metadata.location.link ? <>
                                            <div className="flex items-center my-2 md:my-0">
                                                <a className="flex items-center" href={ pack.metadata.location.link } target="_blank" rel="noreferrer">
                                                    <IoLocationSharp className="text-blue-500"/>
                                                    <p className="text-base bg-neutral-100 text-blue-500">{ pack.metadata.location.name ? pack.metadata.location.name : "" }</p>
                                                </a>
                                                {
                                                    pack.metadata.date !== "0000-01-01" ?
                                                        <p className="ml-2 w-28 text-base font-thin italic bg-neutral-100">{ pack.metadata.date }</p> :
                                                        <></>
                                                }
                                            </div>
                                        </> : <>
                                            <div className="flex items-center my-2 md:my-0">
                                                {
                                                    pack.metadata.location.name ? 
                                                        <div className="flex items-center">
                                                            <IoLocationSharp />
                                                            <p className="text-base bg-neutral-100 mr-2">{ pack.metadata.location.name }</p>
                                                        </div> : <></>
                                                }
                                                {
                                                    pack.metadata.date !== "0000-01-01" ?
                                                        <p className="w-28 text-base font-thin italic bg-neutral-100">{ pack.metadata.date }</p> : <></>
                                                }
                                            </div>
                                        </>
                                    }
                                    <div className="flex items-center">
                                        <a href={ `https://twitter.com/intent/tweet?text=${ shareText }` } target="_blank" rel="noreferrer">
                                            <IoLogoTwitter className="text-xl mr-2" />
                                        </a>
                                        <a href={ `https://www.facebook.com/dialog/share?app_id=400925148544601&display=popup&href=https%3A%2F%2Fultralitepack.com/pack/${ pack.metadata.id }&quote=${ shareText }` } target="_blank" rel="noreferrer">
                                            <IoLogoFacebook className="text-xl mr-2" />
                                        </a>
                                        <IoIosLink className="text-xl cursor-pointer" onClick={() => {
                                            setCopied(1);
                                            navigator.clipboard.writeText(`${ window.location.protocol }//${ window.location.host }/pack/${ pack.metadata.id }`)
                                        }} />
                                        {
                                            copied > 0 ?
                                                <div className="absolute mt-20 ml-5">
                                                    <div className="flex justify-center">
                                                        <div className="absolute top-[-12px] w-6 overflow-hidden inline-block">
                                                            <div className="h-4 w-4 bg-blue-500 rotate-45 transform origin-bottom-left" />
                                                        </div>
                                                        <div className="text-xs text-center bg-blue-500 text-neutral-100 px-3 md:px-4 py-2">link copied</div>
                                                    </div>
                                                </div> : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* Pack */}
                            <div className="w-full mt-4">
                                <div className="w-full">
                                    { pack.pockets.map((pocket, idx) => <span key={ idx }>
                                        <div className="h-11 bg-neutral-700 text-neutral-100 px-4 py-2 mt-1 items-center grid grid-cols-[35%,5%,20%,20%,20%] md:grid-cols-[65%,5%,10%,10%,10%]">
                                            <p className="overflow-hidden text-ellipsis whitespace-nowrap text-lg font-bold">{ pocket.name }</p>
                                            <p/>
                                            <p className="text-base font-bold">{ idx === 0 ? "Amt" : "" }</p>
                                            <p className="text-base font-bold">{ idx === 0 ? "Price" : "" }</p>
                                            <p className="text-base font-bold">{ idx === 0 ? "Weight" : "" }</p>
                                        </div>
                                        { pocket.items.map((item, idx) => <span key={idx}>
                                            <div className="bg-neutral-200 mt-1 px-4 py-2 w-full grid grid-cols-[40%,20%,20%,20%] md:grid-cols-[20%,50%,10%,10%,10%] box-border">
                                                {
                                                    item.link ? <>
                                                        <a href={ item.link } target="_blank" rel="noreferrer" className="text-blue-500">
                                                            <p className="overflow-hidden text-ellipsis whitespace-nowrap order-1">{ item.name }</p>
                                                        </a>
                                                    </> : <p className="overflow-hidden text-ellipsis whitespace-nowrap order-1">{ item.name }</p>
                                                }
                                                <p className="overflow-hidden text-ellipsis w-[19rem] order-last md:order-2 font-extralight">{ item.description }</p>
                                                <p className="order-3 font-extralight">{ item.amount }</p>
                                                <p className="order-4 font-extralight">${ Math.round(item.price) }</p>
                                                <p className="order-5 font-extralight">{ ["lb", "kg", "oz"].includes(units) ? 
                                                    Convert(item.weight, item.units, units).toFixed(2) : 
                                                    Convert(item.weight, item.units, units).toFixed(0) }{ units }</p>
                                            </div>
                                        </span>) }
                                        {
                                            pack.pockets.length <= 1 ? <></> : <>
                                                <div className="bg-neutral-200 mt-1 px-4 py-2 w-full grid grid-cols-[40%,20%,20%,20%] md:grid-cols-[20%,50%,10%,10%,10%] box-border">
                                                    <p className="order-1 font-bold">Total</p>
                                                    <p className="order-last md:order-2"/>
                                                    <p className="order-3 font-bold">{ pocket.items.reduce((sum, item) => sum + item.amount, 0) }</p>
                                                    <p className="order-4 font-bold">${ Math.round(pocket.items.reduce((sum, item) => sum + item.amount * item.price, 0)) }</p>
                                                    <p className="order-5 font-bold">{ ["lb", "kg", "oz"].includes(units) ? 
                                                        pocket.items.reduce((sum, item) => sum + Convert(item.weight, item.units, units), 0).toFixed(2) : 
                                                        pocket.items.reduce((sum, item) => sum + Convert(item.weight, item.units, units), 0).toFixed(0) }{ units }</p>
                                                </div>
                                            </>
                                        }
                                    </span>) }
                                    <div className="bg-neutral-700 text-neutral-100 px-4 py-2 mt-1 items-center grid grid-cols-[40%,0%,20%,20%,20%] md:grid-cols-[20%,50%,10%,10%,10%]">
                                        <p className="text-lg font-bold">Total</p>
                                        <p className="order-2"/>
                                        <p className="order-3 font-bold">{ pack.pockets.reduce((sum, pocket) => sum + pocket.items.reduce((sum, item) => sum + item.amount, 0), 0) }</p>
                                        <p className="order-4 font-bold">${ Math.round(pack.pockets.reduce((sum, pocket) => sum + pocket.items.reduce((sum, item) => sum + item.amount * item.price, 0), 0)) }</p>
                                        <p className="order-5 font-bold">{ ["lb", "kg", "oz"].includes(units) ? 
                                            pack.pockets.reduce((sum, pocket) => sum + pocket.items.reduce((sum, item) => sum + Convert(item.amount * item.weight, item.units, units), 0), 0).toFixed(2) : 
                                            pack.pockets.reduce((sum, pocket) => sum + pocket.items.reduce((sum, item) => sum + Convert(item.amount * item.weight, item.units, units), 0), 0).toFixed(0) }{ units }</p>
                                    </div>
                                </div>
                            </div>
                            {
                                user && user.uid === pack.metadata.author.id ? <>
                                    <div className="mt-2 w-full flex justify-between">
                                        <div/>
                                        <div className="flex">
                                            <Link to={ `/pack/${ pack.metadata.id }/edit` }>
                                                <button className="ml-2 bg-blue-500 text-neutral-100 text-sm px-4 py-2">Edit</button>
                                            </Link>
                                        </div>
                                    </div>
                                </> : <></>
                            }
                            {/* Footer */}
                            <div className="w-full flex justify-center items-center mt-8 flex-wrap">
                                <Link to="/" className="order-3 md:order-1 my-4 md:my-2">
                                    <img className="h-6 md:h-8" src={DarkLogo} alt="ultralitepack"/>
                                </Link>
                                <div className="order-2 md:mx-4 md:h-6 md:border-l border-neutral-700"/>
                                <div className="flex items-center order-1 md:order-3">
                                    <p className="text-sm md:text-base font-thin mr-2">Want to build your own pack?</p>
                                    <Link to={ !user ? "/" : "/packs" }>
                                        <button className="bg-neutral-700 text-neutral-100 text-xs md:text-sm px-4 py-2 mr-2">Build Now</button>
                                    </Link>
                                </div>
                            </div>
                        </Suspense>
                    </div>
                </div>
            </> : <></>
        }
        </>
    )
}
