/**
 * @generated SignedSource<<a3b498ecc97892eb4dd922e73dd0f06b>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type PocketInput = {|
  id?: ?string,
  name?: ?string,
  items?: ?$ReadOnlyArray<?ItemInput>,
|};
export type ItemInput = {|
  price?: ?number,
  weight?: ?number,
  units?: ?string,
  id?: ?string,
  name?: ?string,
  link?: ?string,
  description?: ?string,
  amount?: ?number,
|};
export type EditPackPageAddPocketMutation$variables = {|
  id: string,
  pocket: PocketInput,
  jwt: string,
|};
export type EditPackPageAddPocketMutation$data = {|
  +addPocket: ?{|
    +id: ?string,
    +name: ?string,
    +items: ?$ReadOnlyArray<?{|
      +id: ?string,
      +name: ?string,
      +link: ?string,
      +description: ?string,
      +amount: ?number,
      +price: ?number,
      +weight: ?number,
      +units: ?string,
    |}>,
  |},
|};
export type EditPackPageAddPocketMutation = {|
  variables: EditPackPageAddPocketMutation$variables,
  response: EditPackPageAddPocketMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jwt"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pocket"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "jwt",
        "variableName": "jwt"
      },
      {
        "kind": "Variable",
        "name": "pocket",
        "variableName": "pocket"
      }
    ],
    "concreteType": "Pocket",
    "kind": "LinkedField",
    "name": "addPocket",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Item",
        "kind": "LinkedField",
        "name": "items",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "link",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "price",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "weight",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "units",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditPackPageAddPocketMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditPackPageAddPocketMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "7ce17fb86b7534d8f5b45fa6803f6413",
    "id": null,
    "metadata": {},
    "name": "EditPackPageAddPocketMutation",
    "operationKind": "mutation",
    "text": "mutation EditPackPageAddPocketMutation(\n  $id: String!\n  $pocket: PocketInput!\n  $jwt: String!\n) {\n  addPocket(id: $id, pocket: $pocket, jwt: $jwt) {\n    id\n    name\n    items {\n      id\n      name\n      link\n      description\n      amount\n      price\n      weight\n      units\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "cb5bec40c626cccb0c5adef4ca5025d7";

module.exports = ((node/*: any*/)/*: Mutation<
  EditPackPageAddPocketMutation$variables,
  EditPackPageAddPocketMutation$data,
>*/);
