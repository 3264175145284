/**
 * @generated SignedSource<<02380392557a63f7c5bc9c320bdbe410>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ItemInput = {|
  price?: ?number,
  weight?: ?number,
  units?: ?string,
  id?: ?string,
  name?: ?string,
  link?: ?string,
  description?: ?string,
  amount?: ?number,
|};
export type EditPackPageAddItemMutation$variables = {|
  id: string,
  pocketID: string,
  item: ItemInput,
  jwt: string,
|};
export type EditPackPageAddItemMutation$data = {|
  +addItem: ?{|
    +id: ?string,
    +name: ?string,
    +link: ?string,
    +description: ?string,
    +amount: ?number,
    +price: ?number,
    +weight: ?number,
    +units: ?string,
  |},
|};
export type EditPackPageAddItemMutation = {|
  variables: EditPackPageAddItemMutation$variables,
  response: EditPackPageAddItemMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "item"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jwt"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pocketID"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "item",
        "variableName": "item"
      },
      {
        "kind": "Variable",
        "name": "jwt",
        "variableName": "jwt"
      },
      {
        "kind": "Variable",
        "name": "pocketID",
        "variableName": "pocketID"
      }
    ],
    "concreteType": "Item",
    "kind": "LinkedField",
    "name": "addItem",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "link",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "weight",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "units",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditPackPageAddItemMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditPackPageAddItemMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "ee15897440556c966eafdc38006ae19a",
    "id": null,
    "metadata": {},
    "name": "EditPackPageAddItemMutation",
    "operationKind": "mutation",
    "text": "mutation EditPackPageAddItemMutation(\n  $id: String!\n  $pocketID: String!\n  $item: ItemInput!\n  $jwt: String!\n) {\n  addItem(id: $id, pocketID: $pocketID, item: $item, jwt: $jwt) {\n    id\n    name\n    link\n    description\n    amount\n    price\n    weight\n    units\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "c78cee111f58cf3aa87c0e8edee6f31b";

module.exports = ((node/*: any*/)/*: Mutation<
  EditPackPageAddItemMutation$variables,
  EditPackPageAddItemMutation$data,
>*/);
