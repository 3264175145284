import React, { useEffect } from 'react';
import {useDispatch} from "react-redux";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { setUser } from '../store/actions';
import LoadEditPackPage from "./EditPackPage";
import HomePage from "./HomePage";
import LoginPage from "./LoginPage";
import PackPage from "./PackPage";
import PacksPage from "./PacksPage";
import SignUpPage from "./SignUpPage";
import TermsPage from './TermsPage';
import PrivacyPage from './PrivacyPage';

export default function Routing() {

  const auth = getAuth()
  const dispatch = useDispatch()

  useEffect(() => {
    auth.onAuthStateChanged(async user => {
        if (user) dispatch(setUser(user))
        else dispatch(setUser(null))
    })
  }, [auth, dispatch])

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/pack/:id" element={ <PackPage /> }/>
        <Route exact path="/pack/:id/edit" element={ <LoadEditPackPage /> }/>
        <Route exact path="/packs" element={ <PacksPage /> }/>
        <Route exact path="/login" element={ <LoginPage /> }/>
        <Route exact path="/signup" element={ <SignUpPage /> }/>
        <Route exact path="/terms" element={ <TermsPage /> }/>
        <Route exact path="/privacy" element={ <PrivacyPage /> }/>
        <Route exact path="/" element={ <HomePage /> }/>
        <Route path="*" element={ <Navigate to="/" /> } />
      </Routes>
    </BrowserRouter>
  )
}
