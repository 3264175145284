import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Relay from './components/Relay';
import { Provider } from 'react-redux'
import store from "./store/reduxStore";
import { firebaseConfig } from "./firebase_config";
import { initializeApp } from "firebase/app";

initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}> 
      <Relay />
    </Provider>
);
