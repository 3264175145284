import { useState, useEffect } from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { BsGoogle } from 'react-icons/bs';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useNavigate } from "react-router-dom";

export default function LoginPage() {

    const auth = getAuth();
    const navigate = useNavigate()

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [message, setMessage] = useState("")

    const handleLogin = () => 
        signInWithEmailAndPassword(auth, email, password).then(() => navigate("/packs"))
        .catch(() => setMessage("We could not create account."))

    const handleGoogleLogin = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider).then(() => navigate("/packs"))
        .catch(() => setMessage("Failed to authenticate with Google."));
    }

    useEffect(() => auth.onAuthStateChanged(async user => user && navigate("/packs"), [auth, navigate]))

    return (
        <div className="flex flex-col items-center m-8 md:p-12">
            <div className="w-full max-w-2xl flex flex-col items-center">
                <Navbar />
                <span className="fade-in w-full flex flex-col items-center">
                    <div className="w-72 flex flex-col items-center">
                        <p className="text-2xl font-bold mt-12">Login</p>
                        <input className="w-full h-12 mt-2 bg-neutral-200 box-border p-4 text-neutral-700 focus:outline-neutral-700 font-bold text-base" placeholder="Email" type="text" value={ email } onChange={ e => setEmail(e.target.value) } />
                        <input className="w-full h-12 mt-2 bg-neutral-200 box-border p-4 text-neutral-700 focus:outline-neutral-700 font-bold text-base" placeholder="Password" type="password" value={ password } onChange={ e => setPassword(e.target.value) } />
                        <div className="w-full flex justify-between items-center mt-2">
                            <p className="w-44 font-thin text-sm text-neutral-700">{ message }</p>
                            <button className="bg-neutral-700 text-neutral-100 text-xl px-4 py-2" onClick={ handleLogin }>Login</button>
                        </div>
                    </div>
                    <div className="w-72 mt-12 mb-10">
                        <p className="w-full text-center border-b leading-[.1rem] border-neutral-700">
                            <span className="px-2 bg-neutral-100 font-bold">Or</span>
                        </p>
                    </div>
                    <div className="w-72">
                        <button className="w-full h-12 mt-2 bg-[#4285F4] box-border p-4 text-neutral-100 font-bold text-base flex justify-center items-center" onClick={ handleGoogleLogin }>
                            <BsGoogle className="mr-2"/>
                            <p>Login With Google</p>
                        </button>
                    </div>
                </span>
            </div>
            <div className="absolute bottom-8 md:bottom-12">
                <Footer />
            </div>
        </div>
    )
}
