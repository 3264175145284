/**
 * @generated SignedSource<<654c3cc67722b4473d35369c7912d0bf>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type EditPackPageDeletePocketMutation$variables = {|
  id: string,
  pocketID: string,
  jwt: string,
|};
export type EditPackPageDeletePocketMutation$data = {|
  +deletePocket: ?string,
|};
export type EditPackPageDeletePocketMutation = {|
  variables: EditPackPageDeletePocketMutation$variables,
  response: EditPackPageDeletePocketMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jwt"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pocketID"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "jwt",
        "variableName": "jwt"
      },
      {
        "kind": "Variable",
        "name": "pocketID",
        "variableName": "pocketID"
      }
    ],
    "kind": "ScalarField",
    "name": "deletePocket",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditPackPageDeletePocketMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditPackPageDeletePocketMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "6cceca7780d846934ef702b0a1828d78",
    "id": null,
    "metadata": {},
    "name": "EditPackPageDeletePocketMutation",
    "operationKind": "mutation",
    "text": "mutation EditPackPageDeletePocketMutation(\n  $id: String!\n  $pocketID: String!\n  $jwt: String!\n) {\n  deletePocket(id: $id, pocketID: $pocketID, jwt: $jwt)\n}\n"
  }
};
})();

(node/*: any*/).hash = "78e0592f38f64c467b86286c0f41ed63";

module.exports = ((node/*: any*/)/*: Mutation<
  EditPackPageDeletePocketMutation$variables,
  EditPackPageDeletePocketMutation$data,
>*/);
