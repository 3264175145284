import { useState, useEffect, useCallback, Suspense } from "react";
import {useSelector} from "react-redux";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { IoCloseSharp } from "react-icons/io5"
import { useNavigate, Navigate } from "react-router-dom";
import { useMutation, usePreloadedQuery, useQueryLoader } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Loading from "./Loading";
import { DeletePackPopup } from "./EditPackPage";
import {ErrorBoundary} from 'react-error-boundary'

const MyPacksQuery = graphql`
    query PacksPagesMyPacksQuery($jwt: String!) {
        myPacks(jwt: $jwt) {
            metadata{id, name}
        }
    }
`

const BuildPackQuery = graphql`
    mutation PacksPagesBuildPackQuery($pack: PackInput, $jwt: String!) {
        buildPack(pack: $pack, jwt: $jwt){
            metadata{id}
        }
    }
`

export default function LoadedPacksPage() {
    const navigate = useNavigate()

    const user = useSelector(state => state.user)

    const [loaded, setLoaded] = useState(false)

    useEffect(() => { !user && loaded && navigate("/") }, [user, navigate, loaded]);

    const [queryRef, loadQuery] = useQueryLoader(MyPacksQuery)
    const refresh = useCallback(() => { 
        const refresh = async () => {
            const token = await user.getIdToken(false)
            loadQuery({ jwt: token }, {fetchPolicy: 'network-only'})
        }
        if (user) refresh()
    }, [user, loadQuery]);
    useEffect(() => { 
        const refresh = async () => {
            const token = await user.getIdToken(false)
            loadQuery({ jwt: token }, {fetchPolicy: 'network-only'})
        }
        if (user) refresh()
    }, [user, loadQuery])

    useEffect(() => {
        const timeout = setTimeout(() => { !loaded && navigate("/") }, 5000)
        return () => clearTimeout(timeout)
    }, [loaded, navigate])
    useEffect(() => { queryRef ? setTimeout(() => setLoaded(true), 300) : setLoaded(false) }, [queryRef, setLoaded])

    return (
        <ErrorBoundary fallbackRender={() => <Navigate to="/" />}>
            <Suspense fallback={ <Loading /> }>
                { loaded ? <PacksPage queryRef={queryRef} refresh={refresh} /> : <Loading /> }
            </Suspense>
        </ErrorBoundary>
    )
}

function PacksPage({ queryRef, refresh }) {
    const navigate = useNavigate()
    const [packs, setPacks] = useState([])
    const [message, setMessage] = useState("")
    const user = useSelector(state => state.user)
    const [deletePackPopup, setDeletePackPopup] = useState()
    const [buildPack,] = useMutation(BuildPackQuery);
    const { myPacks } = usePreloadedQuery(MyPacksQuery, queryRef) // fix
    useEffect(() => { myPacks && setPacks(myPacks) }, [myPacks, setPacks])

    useEffect(() => {
        const timeout = setTimeout(() => { !myPacks && navigate("/"); }, 5000)
        return () => clearTimeout(timeout)
    }, [myPacks, navigate])

    const handleBuildPack = async () => {
        if (packs.length >= 5) {
            setMessage("5 pack limit reached")
            return
        }
        const token = await user.getIdToken(false)
        const config = { variables: { pack: {}, jwt: token }, onCompleted(data) {
            navigate(`/pack/${data.buildPack.metadata.id}/edit`)
        } }
        buildPack(config)
    }

    return (
        <>{
            myPacks ? <>
                <div className="flex flex-col items-center m-8 md:p-12">
                    {
                        deletePackPopup ? <DeletePackPopup id={ deletePackPopup } closeDeletePackPopup={(deleted) => {
                            setDeletePackPopup()
                            if (deleted) {
                                setMessage("")
                                refresh()
                            }
                        }} /> : <></>
                    }
                    <div className="w-full max-w-2xl flex flex-col items-center">
                        <Navbar />
                        <div className="fade-in w-72 flex flex-col items-center">
                            <p className="text-2xl font-bold mt-12">My Packs</p>
                            <div className="w-full max-h-72 md:max-h-96  mt-2 overflow-y-scroll scrollbar">
                                { packs.map(pack => <Pack key={ pack.metadata.id } id={ pack.metadata.id } name={ pack.metadata.name } setDeletePackPopup={ setDeletePackPopup } />) }
                            </div>
                            <div className="w-full flex justify-between items-center mt-2">
                                <p className="italic">{ message }</p>
                                <button className="bg-neutral-700 text-neutral-100 text-xl px-4 py-2" onClick={handleBuildPack}>New Pack</button>
                            </div>
                        </div>
                    </div>
                    <div className="absolute bottom-8 md:bottom-12">
                        <Footer />
                    </div>
                </div>
            </> : <Loading />
        }
        </>
    )
}

function Pack({ id, name, setDeletePackPopup }) {
    const navigate = useNavigate()

    return (
        <div className="w-full flex mt-2 cursor-pointer">
            <button className="w-60 h-12 bg-neutral-200 flex items-center p-4" onClick={() => navigate(`/pack/${ id }/edit`)}>
                <p className={`font-bold text-lg text-ellipsis overflow-hidden whitespace-nowrap ${ name === "" ? "italic" : "" }`}>{ name === "" ? id : name }</p>
            </button>
            <button className="w-12 h-12 bg-neutral-700 flex items-center justify-center" onClick={() => setDeletePackPopup(id)}>
                <IoCloseSharp className="text-neutral-100 text-xl"/>
            </button>
        </div>
    )
}
