import { AiOutlineLoading3Quarters } from "react-icons/ai"
import Navbar from "./Navbar";
import Footer from "./Footer";

export default function Loading() {
    return (
        <div className="flex flex-col items-center m-8 md:p-12">
            <div className="w-full max-w-2xl flex flex-col items-center">
                <Navbar />
                <div className="flex items-center mt-12 text-xl font-extralight">
                    <AiOutlineLoading3Quarters className="animate-spin mr-2"/> Loading
                </div>
            </div>
            <div className="absolute bottom-8 md:bottom-12">
                <Footer />
            </div>
        </div>
    )
}
