/**
 * @generated SignedSource<<b2a3cde82f6a28a45aab628c69b8384e>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ItemInput = {|
  price?: ?number,
  weight?: ?number,
  units?: ?string,
  id?: ?string,
  name?: ?string,
  link?: ?string,
  description?: ?string,
  amount?: ?number,
|};
export type EditPackPageUpdateItemMutation$variables = {|
  id: string,
  item: ItemInput,
  jwt: string,
|};
export type EditPackPageUpdateItemMutation$data = {|
  +updateItem: ?{|
    +id: ?string,
    +name: ?string,
    +link: ?string,
    +description: ?string,
    +amount: ?number,
    +price: ?number,
    +weight: ?number,
    +units: ?string,
  |},
|};
export type EditPackPageUpdateItemMutation = {|
  variables: EditPackPageUpdateItemMutation$variables,
  response: EditPackPageUpdateItemMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "item"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "jwt"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "item",
        "variableName": "item"
      },
      {
        "kind": "Variable",
        "name": "jwt",
        "variableName": "jwt"
      }
    ],
    "concreteType": "Item",
    "kind": "LinkedField",
    "name": "updateItem",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "link",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "weight",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "units",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditPackPageUpdateItemMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditPackPageUpdateItemMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8f53682c6abcecd221ac7476d7b91433",
    "id": null,
    "metadata": {},
    "name": "EditPackPageUpdateItemMutation",
    "operationKind": "mutation",
    "text": "mutation EditPackPageUpdateItemMutation(\n  $id: String!\n  $item: ItemInput!\n  $jwt: String!\n) {\n  updateItem(id: $id, item: $item, jwt: $jwt) {\n    id\n    name\n    link\n    description\n    amount\n    price\n    weight\n    units\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "47ee5d408b62474443e2f7d8f473204e";

module.exports = ((node/*: any*/)/*: Mutation<
  EditPackPageUpdateItemMutation$variables,
  EditPackPageUpdateItemMutation$data,
>*/);
