import {useSelector} from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import DarkLogo from "../resources/logo_dark.png"
import { getAuth, signOut } from "firebase/auth";

export default function Navbar() {

    const auth = getAuth()
    const user = useSelector(state => state.user);
    const navigate = useNavigate()
    const handleGoogleLogout = () => { signOut(auth) && navigate("/") }

    return (
      <div className="w-full flex justify-between items-center">
        <Link to="/">
            <img className="h-6 md:h-8" src={DarkLogo} alt="ultralitepack"/>
        </Link>
        <div className="flex items-center">
            {
                user ? <span className="flex items-center">
                    <Link to="/packs">
                        <p className="text-base md:text-lg font-bold">Packs</p>
                    </Link>
                    <div className="mx-4 h-6 border-l border-neutral-700"/>
                    <button onClick={ handleGoogleLogout }>
                        <p className="text-base md:text-lg">Logout</p>
                    </button>
                </span> : <span className="flex items-center">
                    <Link to="/signup">
                        <p className="text-base md:text-lg font-bold">Sign Up</p>
                    </Link>
                    <div className="mx-4 h-6 border-l border-neutral-700"/>
                    <Link to="/login">
                        <p className="text-base md:text-lg">Login</p>
                    </Link>
                </span>
            }
        </div>
      </div>
    );
}
