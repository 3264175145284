/**
 * @generated SignedSource<<f19b91368fabf7b15f9c7c00b9ca3511>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type EditPackPageDeletePackMutation$variables = {|
  id: string,
  jwt: string,
|};
export type EditPackPageDeletePackMutation$data = {|
  +deletePack: ?string,
|};
export type EditPackPageDeletePackMutation = {|
  variables: EditPackPageDeletePackMutation$variables,
  response: EditPackPageDeletePackMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "jwt"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "jwt",
        "variableName": "jwt"
      }
    ],
    "kind": "ScalarField",
    "name": "deletePack",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditPackPageDeletePackMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditPackPageDeletePackMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d1385ccbae9e83a3a25b29875774ddb7",
    "id": null,
    "metadata": {},
    "name": "EditPackPageDeletePackMutation",
    "operationKind": "mutation",
    "text": "mutation EditPackPageDeletePackMutation(\n  $id: String!\n  $jwt: String!\n) {\n  deletePack(id: $id, jwt: $jwt)\n}\n"
  }
};
})();

(node/*: any*/).hash = "c07ef6bd8e76ba89612ce7d2c66f5ea6";

module.exports = ((node/*: any*/)/*: Mutation<
  EditPackPageDeletePackMutation$variables,
  EditPackPageDeletePackMutation$data,
>*/);
