export default function Footer() {
  return (
    <div className="flex justify-center w-full font-light text-base">
        <a href="mailto:hello@ultralitepack.com">
            <p>Contact</p>
        </a>
        <p className="mx-4">/</p>
        <a href="https://www.buymeacoffee.com/ultralitepack" target="_blank" rel="noreferrer">
            <p>Support Us</p>
        </a>
    </div>
  );
}
