export const Convert = (weight, unitsFrom, unitTo) => {
    if (unitsFrom === "oz") {
        if (unitTo === "lb") return weight * 0.0625
        if (unitTo === "g") return weight * 28.3495
        if (unitTo === "kg") return weight * 0.0283495
    }
    if (unitsFrom === "lb") {
        if (unitTo === "oz") return weight * 16
        if (unitTo === "g") return weight * 453.592
        if (unitTo === "kg") return weight * 0.453592
    }
    if (unitsFrom === "g") {
        if (unitTo === "oz") return weight * 0.035274
        if (unitTo === "lb") return weight * 0.00220462
        if (unitTo === "kg") return weight * 0.001
    }
    if (unitsFrom === "kg") {
        if (unitTo === "oz") return weight * 35.274
        if (unitTo === "g") return weight * 1000
        if (unitTo === "lb") return weight * 2.20462
    }
    return weight
}
