import { useState, useEffect, Suspense, useCallback } from "react";
import { IoCloseSharp } from "react-icons/io5"
import { IoIosLink } from "react-icons/io"
import { AiOutlineLoading3Quarters } from "react-icons/ai"
import MoneyInput from "@rschpdr/react-money-input";
import Navbar from "./Navbar";
import { Link, Navigate, useParams, useNavigate } from "react-router-dom";
import { Convert } from "./convert";
import { useMutation, usePreloadedQuery, useQueryLoader } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import { ErrorBoundary } from 'react-error-boundary'
import { useSelector } from "react-redux";
import Loading from "./Loading";

const debounce = 500
const maxLength = 100

const MyPackQuery = graphql`
    query EditPackPageMyPackQuery($id: String!, $jwt: String!) {
        myPack(id: $id, jwt: $jwt) {
            metadata{id,createdAt,name,author{id},date,location{name,link},defaultUnits},
            pockets{id,name,items{id,name,link,description,amount,price,weight,units}}
        }
    }
`

const AddPocketMutation = graphql`
    mutation EditPackPageAddPocketMutation($id: String!, $pocket: PocketInput!, $jwt: String!) {
        addPocket(id: $id, pocket: $pocket, jwt: $jwt) {
            id,name,items{id,name,link,description,amount,price,weight,units}
        }
    }
`

const AddItemMutation = graphql`
    mutation EditPackPageAddItemMutation($id: String!, $pocketID: String!, $item: ItemInput!, $jwt: String!) {
        addItem(id: $id, pocketID: $pocketID, item: $item, jwt: $jwt) {
            id,name,link,description,amount,price,weight,units
        }
    }
`

const UpdateMetadataMutation = graphql`
    mutation EditPackPageUpdateMetadataMutation($metadata: MetadataInput!, $jwt: String!) {
        updateMetadata(metadata: $metadata, jwt: $jwt) {
            id,createdAt,name,author{id},date,location{name,link},defaultUnits
        }
    }
`

const UpdatePocketMutation = graphql`
    mutation EditPackPageUpdatePocketMutation($id: String!, $pocket: PocketInput!, $jwt: String!) {
        updatePocket(id: $id, pocket: $pocket, jwt: $jwt) {
            id,name,items{id,name,link,description,amount,price,weight,units}
        }
    }
`

const UpdateItemMutation = graphql`
    mutation EditPackPageUpdateItemMutation($id: String!, $item: ItemInput!, $jwt: String!) {
        updateItem(id: $id, item: $item, jwt: $jwt) {
            id,name,link,description,amount,price,weight,units
        }
    }
`

const DeletePackMutation = graphql`
    mutation EditPackPageDeletePackMutation($id: String!, $jwt: String!) {
        deletePack(id: $id, jwt: $jwt)
    }
`

const DeletePocketMutation = graphql`
    mutation EditPackPageDeletePocketMutation($id: String!, $pocketID: String!, $jwt: String!) {
        deletePocket(id: $id, pocketID: $pocketID, jwt: $jwt)
    }
`

const DeleteItemMutation = graphql`
    mutation EditPackPageDeleteItemMutation($id: String!, $itemID: String!, $jwt: String!) {
        deleteItem(id: $id, itemID: $itemID, jwt: $jwt)
    }
`

export default function LoadEditPackPage() {
    const { id } = useParams()

    const navigate = useNavigate();

    const user = useSelector(state => state.user)

    const [queryRef, loadQuery] = useQueryLoader(MyPackQuery)

    const [loaded, setLoaded] = useState(false)

    const refresh = useCallback(() => {
        const refresh = async () => {
            const token = await user.getIdToken(false)
            loadQuery({ id: id, jwt: token }, {fetchPolicy: 'network-only'})
        }
        if (user) refresh()
    }, [id, user, loadQuery]);

    useEffect(() => {
        const refresh = async () => {
            const token = await user.getIdToken(false)
            loadQuery({ id: id, jwt: token }, {fetchPolicy: 'network-only'})
        }
        if (user) refresh()
    }, [id, user, loadQuery])

    // HACK - pack is loaded in LoadEditPackPageHack but set above to allow for use of pack in suspsnse fallback
    // this allows us to render EditPackPage with old pack data while refrshing
    const [pack, setPack] = useState({metadata:{id: id, name: "", date: "", author: {id: ""}, location: {name: "", link: ""}, defaultUnits: "oz"}, pockets: []})
    
    useEffect(() => {
        const timeout = setTimeout(() => { !loaded && navigate("/") }, 5000)
        return () => clearTimeout(timeout)
    }, [loaded, navigate])
    useEffect(() => { queryRef ? setTimeout(() => setLoaded(true), 300) : setLoaded(false) }, [queryRef, setLoaded])

    return (
        <ErrorBoundary fallbackRender={() => <Navigate to="/packs" />}>
            <Suspense fallback={<EditPackPage id={id} pack={pack} refresh={()=>{}} />}>
                {
                    loaded ? <LoadEditPackPageHack id={id} queryRef={queryRef} refresh={refresh} setPack={setPack} /> : <Loading />
                }
            </Suspense>
        </ErrorBoundary>
    )
}

function LoadEditPackPageHack({queryRef, id, refresh, setPack}) {
    const navigate = useNavigate();
    const { myPack } = usePreloadedQuery(MyPackQuery, queryRef)
    useEffect(() => setPack(myPack), [myPack, setPack])

    useEffect(() => {
        const timeout = setTimeout(() => { !myPack && navigate("/") }, 5000)
        return () => clearTimeout(timeout)
    }, [myPack, navigate])

    return (
        myPack ? <EditPackPage pack={myPack} id={id} refresh={refresh} /> : <Loading />
    )
}

function EditPackPage({pack, id, refresh}) {
    if (!pack) {
        throw new Error(`pack with ID ${id} not found`)
    }

    const navigate = useNavigate();

    const user = useSelector(state => state.user)

    // GraphQL mutations that may be performed on the pack
    const [addPocket, isAddingPocket] = useMutation(AddPocketMutation);
    const [updateMetadata, isMetadataPending] = useMutation(UpdateMetadataMutation);

    const [saving, setSaving] = useState(false)
    const [itemLinkPopup, setItemLinkPopup] = useState()
    const [locationLinkPopup, setLocationLinkPopup] = useState()
    const [deletePackPopup, setDeletePackPopup] = useState()
    
    const [defaultUnits, setDefaultUnits] = useState(pack ? pack.metadata.defaultUnits : "oz")
    const [name, setName] = useState(pack ? pack.metadata.name : "")
    const [date, setDate] = useState(pack ? pack.metadata.date : "")
    const [location, setLocation] = useState(pack && pack.metadata.location.name ? pack.metadata.location.name : "")
    const [showLocation, setShowLocation] = useState(false)
    const [showDate, setShowDate] = useState(false)

    // metadata updates
    const handleMetadataChange = useCallback((metadataConfig) => {
        const match = Object.keys(metadataConfig).reduce((sum, k) => {
            if (k !== "location") return sum && pack.metadata[k] === metadataConfig[k]
            return sum && metadataConfig.location.name === pack.metadata.location.name
        }, true)
        if (pack && !match) setSaving(true)
        const delayDebounceFn = setTimeout(async () => {
            // update if pack was loaded and input metadata does not match current pack
            if (pack && !match) {
                const token = await user.getIdToken(false)
                metadataConfig.id = id
                const config = { variables: { metadata: metadataConfig, jwt: token } };
                updateMetadata(config)
            }
        }, debounce)
        return () => clearTimeout(delayDebounceFn)
    }, [id, user, pack, setSaving, updateMetadata])
    useEffect(() => { !isMetadataPending && setSaving(isMetadataPending) }, [isMetadataPending, setSaving])
    useEffect(() => handleMetadataChange({ defaultUnits: defaultUnits }), [defaultUnits, handleMetadataChange])
    useEffect(() => handleMetadataChange({ name: name }), [name, handleMetadataChange])
    useEffect(() => {
        if (location === "" && !showLocation) return handleMetadataChange({ location: { name: location, link: "" } })
        else return handleMetadataChange({ location: { name: location } })
    }, [location, showLocation, handleMetadataChange])
    useEffect(() => handleMetadataChange({ date: date }), [date, handleMetadataChange])

    const handleAddPocket = useCallback(async () => {
        const token = await user.getIdToken(false)
        const config = { variables: { id: id, pocket: {}, jwt: token } };
        addPocket(config)
    }, [id, user, addPocket])
    useEffect(() => { if (!isAddingPocket) refresh() }, [isAddingPocket, refresh]) 

    return (
        <div className="flex flex-col items-center m-8 md:p-12">
            {
                itemLinkPopup ? <ItemLinkPopup id={id} item={itemLinkPopup} setSaving={setSaving} refresh={refresh} closeItemLinkPopup={() => setItemLinkPopup() } /> : <></>
            }
            {
                locationLinkPopup ? <LocationLinkPopup id={id} location={pack.metadata.location} setSaving={setSaving} refresh={refresh} closeLocationLinkPopup={() => setLocationLinkPopup() } /> : <></>
            }
            {
                deletePackPopup ? <DeletePackPopup id={id} closeDeletePackPopup={(deleted) => {
                    setDeletePackPopup()
                    if (deleted) navigate("/packs")
                }} /> : <></>
            }
            <div className="w-full max-w-2xl flex flex-col items-center">
                <Navbar />
                <div className="fade-in w-full flex flex-col mt-12">
                    {/* Title */}
                    <div className="w-full flex flex-col">
                        <div className="w-full flex justify-between">
                            <div className="w-full flex mr-4">
                                <input maxLength={ maxLength } className="w-full h-12 bg-neutral-200 box-border p-4 text-neutral-700 focus:outline-neutral-700 font-bold text-base" type="text" placeholder="Pack Name" value={ name } onChange={ e => setName(e.target.value) } />
                                <button className="w-12 h-12 bg-neutral-700 flex items-center justify-center" onClick={() => setDeletePackPopup(true)}>
                                    <IoCloseSharp className="text-neutral-100 text-xl"/>
                                </button>
                            </div>
                            <div className="flex items-center">
                                <p className="text-2xl font-bold">{ ["lb", "kg", "oz"].includes(defaultUnits) ? 
                                    pack.pockets.reduce((sum, pocket) => sum + pocket.items.reduce((sum, item) => sum + Convert(item.amount * item.weight, item.units, defaultUnits), 0), 0).toFixed(2) : 
                                    pack.pockets.reduce((sum, pocket) => sum + pocket.items.reduce((sum, item) => sum + Convert(item.amount * item.weight, item.units, defaultUnits), 0), 0).toFixed(0) }</p>
                                <select className="font-bold bg-neutral-100 focus:outline-none h-full dropdown-input px-1 w-10" value={ defaultUnits } onChange={ e => setDefaultUnits(e.target.value) }>
                                    <option value="oz">oz</option>
                                    <option value="lb">lb</option>
                                    <option value="g">g</option>
                                    <option value="kg">kg</option>
                                </select>
                            </div>
                        </div>
                        <div className="flex mt-1 w-full flex-wrap items-center">
                            {
                                showLocation || location ? 
                                    <div className="flex mr-2 bg-neutral-200 items-center">
                                        <input maxLength={ maxLength } className="h-10 p-4 bg-neutral-200 box-border text-neutral-700 focus:outline-neutral-700 text-base number-input" placeholder="Location" value={ location } type="text" onChange={ e => setLocation(e.target.value) }/>
                                        <button onClick={ () => setLocationLinkPopup(true) }>
                                            {
                                                pack.metadata.location.link ? 
                                                    <IoIosLink className="mx-1 text-blue-500" /> :
                                                    <IoIosLink className="mx-1 text-neutral-700" />
                                            }
                                        </button>
                                        <button className="w-10 h-10 bg-neutral-700 flex items-center justify-center" onClick={ () => {
                                            setShowLocation(false)
                                            setLocation("")
                                        } }>
                                            <IoCloseSharp className="text-neutral-100 text-sm"/>
                                        </button>
                                    </div> :
                                    <button className="h-10 bg-neutral-700 text-neutral-100 text-sm px-4 py-2 mr-2" onClick={ () => setShowLocation(true) }>+ Location</button>
                            }
                            {
                                showDate || date !== "0000-01-01" ? 
                                    <div className="flex bg-neutral-200">
                                        <input className="bg-neutral-200 focus:outline-neutral-700 pl-4 pr-1" type="date" value={ date === "0000-01-01" ? "" : date } onChange={ e => setDate(e.target.value) }/>
                                        <button className="w-10 h-10 bg-neutral-700 flex items-center justify-center" onClick={ () => {
                                            setDate("0000-01-01")
                                            setShowDate(false)
                                        } }>
                                            <IoCloseSharp className="text-neutral-100 text-sm"/>
                                        </button>
                                    </div> :
                                    <button className="h-10 bg-neutral-700 text-neutral-100 text-sm px-4 py-2" onClick={ () => setShowDate(true) }>+ Date</button>
                            }
                            {
                                (showDate || date !== "0000-01-01") && (showLocation || location) ? <div className="h-10 w-0 mt-2 md:mt-0"/> : <></>
                            }
                        </div>
                    </div>
                    {/* Pack */}
                    <div>
                        {/* Pockets */}
                        <div className="mt-6">
                            { pack.pockets.map(pocket => <Pocket key={ pocket.id } id={ id } pocket={ pocket } setSaving={ setSaving } refresh={ refresh } setItemLinkPopup={ setItemLinkPopup } />) }
                        </div>
                        <div className="w-full flex justify-between mt-2">
                            <button className="bg-neutral-700 text-neutral-100 text-base px-4 py-2" onClick={handleAddPocket}>+ Pocket</button>
                            <div className="flex items-end">
                                {
                                    saving ? <>
                                        <div className="flex h-9 items-end text-sm mx-2 text-neutral-400">
                                            <AiOutlineLoading3Quarters className="animate-spin mr-2"/> Saving
                                        </div>
                                    </> : <div className="flex h-9 items-end text-sm mx-2 text-neutral-400">Changes saved</div>
                                }
                                <Link to={`/pack/${id}`}>
                                    <button className="bg-blue-500 text-neutral-100 text-sm px-4 py-2">View</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Pocket({ id, pocket, setSaving, refresh, setItemLinkPopup }) {
    const user = useSelector(state => state.user)

    const [addItem, isAddingItem] = useMutation(AddItemMutation)
    const [updatePocket, isPocketUpdating] = useMutation(UpdatePocketMutation);
    const [deletePocket, isPocketDeleting] = useMutation(DeletePocketMutation);
    const [deleteTriggered, setDeleteTriggered] = useState(false)
    const [name, setName] = useState(pocket ? pocket.name : "")
    const handlePocketChange = useCallback((pocketConfig) => {
        if (pocket && pocket.name !== name) setSaving(true)
        const delayDebounceFn = setTimeout(async () => {
            if (pocket && pocket.name !== name) {
                const token = await user.getIdToken(false)
                pocketConfig.id = pocket.id
                const config = { variables: { id: id, pocket: pocketConfig, jwt: token } };
                updatePocket(config)
            }
        }, debounce)
        return () => clearTimeout(delayDebounceFn)
    }, [id, user, name, pocket, setSaving, updatePocket])
    useEffect(() => { !isPocketUpdating && setSaving(isPocketUpdating) }, [isPocketUpdating, setSaving])
    useEffect(() => handlePocketChange({ name: name }), [name, handlePocketChange])

    const handleAddItem = useCallback(async () => {
        const token = await user.getIdToken(false)
        const config = { variables: { id: id, pocketID: pocket.id, item: {}, jwt: token } };
        addItem(config)
    }, [id, user, pocket, addItem])
    useEffect(() => { if (!isAddingItem) refresh() }, [isAddingItem, refresh])

    const handleDeletePocket = async () => {
        const token = await user.getIdToken(false)
        deletePocket({ variables: { id: id, pocketID: pocket.id, jwt: token } })
        setDeleteTriggered(true)
    }
    useEffect(() => { 
        if (!isPocketDeleting && deleteTriggered) {
            refresh()
            setDeleteTriggered(false)
        }
    }, [isPocketDeleting, deleteTriggered, refresh])

    return (
        <span key={ pocket.id }>                   
            <div className="w-full flex items-end flex-wrap mt-2">
                <div className="w-full md:w-[56.5%] flex">
                    <input maxLength={ maxLength } className="w-[calc(100%-3rem)] h-12 bg-neutral-200 box-border p-4 text-neutral-700 focus:outline-neutral-700 font-bold text-base" type="text" placeholder="Pocket Name" value={ name } onChange={e => setName(e.target.value) } />
                    <button className="md:mr-2 w-12 h-12 bg-neutral-700 flex items-center justify-center" onClick={handleDeletePocket}>
                        <IoCloseSharp className="text-neutral-100 text-xl"/>
                    </button>
                </div>
                <p className="hidden md:block md:w-[9%] font-bold">Amt</p>
                <p className="hidden md:block md:w-[10%] font-bold">Price</p>
                <p className="hidden md:block md:w-[10%] font-bold">Weight</p>
            </div>
            { pocket.items.map(item => <Item key={ item.id } id={ id } item={ item } setSaving={ setSaving } refresh={ refresh } setItemLinkPopup={ setItemLinkPopup } />) }
            <button className="mt-1 bg-neutral-700 text-neutral-100 text-sm px-4 py-2" onClick={ handleAddItem }>+ Item</button>
        </span>
    )
}

function Item({ id, item, setSaving, refresh, setItemLinkPopup }) {
    const user = useSelector(state => state.user)

    const [updateItem, isItemUpdating] = useMutation(UpdateItemMutation);
    const [deleteItem, isItemDeleting] = useMutation(DeleteItemMutation);
    const [deleteTriggered, setDeleteTriggered] = useState(false)
    const [name, setName] = useState(item ? item.name : "")
    const [description, setDescription] = useState(item ? item.description : "")
    const [amount, setAmount] = useState(item ? item.amount : 0)
    const [price, setPrice] = useState(item ? item.price : 0)
    const [weight, setWeight] = useState(item ? item.weight : 0)
    const [units, setUnits] = useState(item ? item.units : "")

    const handleItemChange = useCallback((itemConfig) => {
        if (item && !Object.keys(itemConfig).reduce((sum, k) => sum && item[k] === itemConfig[k], true)) setSaving(true)
        const delayDebounceFn = setTimeout(async () => {
            if (item && !Object.keys(itemConfig).reduce((sum, k) => sum && item[k] === itemConfig[k], true)) {
                const token = await user.getIdToken(false)
                itemConfig.id = item.id
                const config = { variables: { id: id, item: itemConfig, jwt: token } };
                updateItem(config)
            }
        }, debounce)
        return () => clearTimeout(delayDebounceFn)
    }, [id, user, item, setSaving, updateItem])
    useEffect(() => { !isItemUpdating && setSaving(isItemUpdating) }, [isItemUpdating, setSaving])
    useEffect(() => handleItemChange({ name: name }), [name, handleItemChange])
    useEffect(() => handleItemChange({ description: description }), [description, handleItemChange])
    useEffect(() => handleItemChange({ amount: amount }), [amount, handleItemChange])
    useEffect(() => handleItemChange({ price: price }), [price, handleItemChange])
    useEffect(() => handleItemChange({ weight: weight }), [weight, handleItemChange])
    useEffect(() => handleItemChange({ units: units }), [units, handleItemChange])

    const handleDeleteItem = async () => {
        const token = await user.getIdToken(false)
        deleteItem({ variables: { id: id, itemID: item.id, jwt: token } })
        setDeleteTriggered(true)
    }
    useEffect(() => { 
        if (!isItemDeleting && deleteTriggered) {
            refresh()
            setDeleteTriggered(false)
        }
    }, [isItemDeleting, deleteTriggered, refresh])

    return (
        <span key={ item.id }>
            <div className="w-full h-full flex mt-1 bg-neutral-200">
                <div className="w-[calc(100%-2rem)] h-full flex items-center flex-wrap pl-4 py-2">
                    <input maxLength={ maxLength } className="mr-1 w-[calc(35%-.25rem)] md:w-[calc(20%-.25rem)] order-1 h-6 bg-neutral-200 box-border text-neutral-700 focus:outline-neutral-700 text-base" placeholder="Name" type="text" value={ name } onChange={ e => setName(e.target.value) } />
                    <input maxLength={ maxLength } className="mb-1 md:mb-0 md:mr-1 w-[100%] md:w-[calc(40%-.25rem)] order-last md:order-2 h-6 bg-neutral-200 box-border text-neutral-700 focus:outline-neutral-700 text-base font-extralight" placeholder="Description" type="text" value={ description } onChange={ e => setDescription(e.target.value) } />
                    <input className="mr-1 w-[calc(10%-.25rem)] md:w-[calc(9%-.25rem)] order-3 h-6 bg-neutral-200 box-border text-neutral-700 focus:outline-neutral-700 text-base number-input font-extralight" placeholder="Amt" type="number" value={ amount } onChange={ e => setAmount(e.target.value.slice(0, 3)) } />
                    <MoneyInput maxLength={ maxLength } className="mr-1 w-[calc(21%-.25rem)] md:w-[calc(11%-.25rem)] order-4 h-6 bg-neutral-200 box-border text-neutral-700 focus:outline-neutral-700 text-base number-input font-extralight" max={999999} placeholder="Price" value={ price } onChange={ e => setPrice(e.target.value) } />
                    <input className="mr-1 w-[calc(14%-.25rem)] md:w-[calc(10%-.25rem)] order-5 h-6 bg-neutral-200 box-border text-neutral-700 focus:outline-neutral-700 text-base number-input font-extralight" placeholder="Wt" type="number" min="0" max="999" value={ weight } onChange={ e => setWeight(e.target.value.slice(0, 6)) } />
                    <select className="w-[12%] md:w-[6%] order-6 bg-neutral-200 text-[#9da3ae] focus:outline-none h-full text-sm dropdown-input" value={ units } onChange={ e => setUnits(e.target.value) } >
                        <option value="oz">oz</option>
                        <option value="lb">lb</option>
                        <option value="g">g</option>
                        <option value="kg">kg</option>
                    </select>
                    <div className="w-[3%] md:w-[4%] order-7 flex items-center">
                        <button onClick={ () => setItemLinkPopup(item) } >
                            {
                                item.link ? 
                                    <IoIosLink className="mx-1 text-blue-500" /> :
                                    <IoIosLink className="mx-1 text-neutral-700" />
                            }
                        </button>
                    </div>
                </div>
                <button className="w-8 h-8 md:w-10 md:h-10 bg-neutral-700 flex items-center justify-center" onClick={ handleDeleteItem }>
                    <IoCloseSharp className="text-neutral-100 text-sm"/>
                </button>
            </div>
        </span>
    )
}

function ItemLinkPopup({ id, item, setSaving, refresh, closeItemLinkPopup }) {
    const user = useSelector(state => state.user)
    const [updateItem, isItemUpdating] = useMutation(UpdateItemMutation);
    const [link, setLink] = useState(item.link)
    const [submitTriggered, setSubmitTriggered] = useState(false)
    const handleUpdateItemLink = async () => {
        if (!submitTriggered && item.link !== link) {
            setSaving(true)
            const token = await user.getIdToken(false)
            updateItem({ variables: { id: id, item: { id: item.id, link: link }, jwt: token }, onCompleted() {
                setTimeout(() => setSaving(false), 200)
            } })
            setSubmitTriggered(true)
        } else {
            closeItemLinkPopup()
        }
    }
    useEffect(() => { 
        if (!isItemUpdating && submitTriggered) {
            refresh()
            setSubmitTriggered(false)
            closeItemLinkPopup()
        }
    }, [isItemUpdating, submitTriggered, closeItemLinkPopup, refresh])
    return (
        <div className="fade-in fixed h-full w-full top-0">
            <div className="h-full w-full bg-neutral-700 opacity-25"/>
            <div className="fixed h-full w-full top-0 flex items-center justify-center">
                <div className="flex flex-col p-4 bg-neutral-100">
                    <p className="text-base font-bold">Item Link</p>
                    <input maxLength={ maxLength } className="h-12 p-4 my-2 bg-neutral-200 box-border text-neutral-700 focus:outline-neutral-700 text-base" placeholder="Link" value={link} onChange={e => setLink(e.target.value) } onKeyDown={ e => { e.key === 'Enter' && handleUpdateItemLink() } } />
                    <div className="flex justify-between">
                        <button className="px-4 py-2 bg-neutral-700 text-neutral-100" onClick={() => closeItemLinkPopup()}>Cancel</button>
                        <button className="px-4 py-2 bg-blue-500 text-neutral-100" onClick={handleUpdateItemLink}>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function LocationLinkPopup({ id, location, setSaving, refresh, closeLocationLinkPopup }) {
    const user = useSelector(state => state.user)
    const [updateMetadata, isMetadataPending] = useMutation(UpdateMetadataMutation);
    const [link, setLink] = useState(location.link)
    const [submitTriggered, setSubmitTriggered] = useState(false)
    const handleUpdateLocationLink = async () => {
        if (!submitTriggered && location.link !== link) {
            setSaving(true)
            const token = await user.getIdToken(false)
            updateMetadata({ variables: { metadata: { id: id, location: { link: link } }, jwt: token }, onCompleted() {
                setTimeout(() => setSaving(false), 200)
            } })
            setSubmitTriggered(true)
        } else {
            closeLocationLinkPopup()
        }
    }
    useEffect(() => { 
        if (!isMetadataPending && submitTriggered) {
            refresh()
            setSubmitTriggered(false)
            closeLocationLinkPopup()
        }
    }, [isMetadataPending, submitTriggered, closeLocationLinkPopup, refresh])
    return (
        <div className="fade-in fixed h-full w-full top-0">
            <div className="h-full w-full bg-neutral-700 opacity-25"/>
            <div className="fixed h-full w-full top-0 flex items-center justify-center">
                <div className="flex flex-col p-4 bg-neutral-100">
                    <p className="text-base font-bold">Location Link</p>
                    <input maxLength={ maxLength } className="h-12 p-4 my-2 bg-neutral-200 box-border text-neutral-700 focus:outline-neutral-700 text-base" placeholder="Link" value={ link } onChange={e => setLink(e.target.value) } onKeyDown={ e => { e.key === 'Enter' && handleUpdateLocationLink() } } />
                    <div className="flex justify-between">
                        <button className="px-4 py-2 bg-neutral-700 text-neutral-100" onClick={() => closeLocationLinkPopup()}>Cancel</button>
                        <button className="px-4 py-2 bg-blue-500 text-neutral-100" onClick={ handleUpdateLocationLink }>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function DeletePackPopup({ id, closeDeletePackPopup }) {
    const [deletePack, isDeletingPack] = useMutation(DeletePackMutation);
    const [submitTriggered, setSubmitTriggered] = useState(false)
    const user = useSelector(state => state.user)

    const handleDeletePack = useCallback(async () => {
        const token = await user.getIdToken(false)
        const config = { variables: { id: id, jwt: token } };
        deletePack(config)
        setSubmitTriggered(true)
    }, [id, user, deletePack])
    useEffect(() => {
        if (!isDeletingPack && submitTriggered) {
            setSubmitTriggered(false)
            closeDeletePackPopup(true)
        }
    }, [isDeletingPack, submitTriggered, closeDeletePackPopup])
    return (
        <div className="fade-in fixed h-full w-full top-0">
            <div className="h-full w-full bg-neutral-700 opacity-25"/>
            <div className="fixed h-full w-full flex items-center justify-center top-0">
                <div className="w-56 flex flex-col p-4 bg-neutral-100">
                    <p className="text-base font-bold">Delete Pack</p>
                    <p className="text-sm font-extralight">Are you sure you want to delete this pack? All data will be lost.</p>
                    <div className="flex justify-between mt-2">
                        <button className="px-4 py-2 bg-neutral-700 text-neutral-100" onClick={() => closeDeletePackPopup(false) }>Cancel</button>
                        <button className="px-4 py-2 bg-red-500 text-neutral-100" onClick={ handleDeletePack }>Delete</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
