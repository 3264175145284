/**
 * @generated SignedSource<<3307e0a5460adde5b753ce1603fc3502>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type EditPackPageDeleteItemMutation$variables = {|
  id: string,
  itemID: string,
  jwt: string,
|};
export type EditPackPageDeleteItemMutation$data = {|
  +deleteItem: ?string,
|};
export type EditPackPageDeleteItemMutation = {|
  variables: EditPackPageDeleteItemMutation$variables,
  response: EditPackPageDeleteItemMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "itemID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "jwt"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "itemID",
        "variableName": "itemID"
      },
      {
        "kind": "Variable",
        "name": "jwt",
        "variableName": "jwt"
      }
    ],
    "kind": "ScalarField",
    "name": "deleteItem",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditPackPageDeleteItemMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditPackPageDeleteItemMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bb92cd3016d80f1afee1baf69806fe3e",
    "id": null,
    "metadata": {},
    "name": "EditPackPageDeleteItemMutation",
    "operationKind": "mutation",
    "text": "mutation EditPackPageDeleteItemMutation(\n  $id: String!\n  $itemID: String!\n  $jwt: String!\n) {\n  deleteItem(id: $id, itemID: $itemID, jwt: $jwt)\n}\n"
  }
};
})();

(node/*: any*/).hash = "1faa8ede9060978abd512c1cbeec58a7";

module.exports = ((node/*: any*/)/*: Mutation<
  EditPackPageDeleteItemMutation$variables,
  EditPackPageDeleteItemMutation$data,
>*/);
