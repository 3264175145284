import { Link } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar";

export default function HomePage() {
  return (
    <div className="flex flex-col items-center m-8 md:p-12">
      <div className="w-full max-w-2xl">
        <Navbar />
        <div className="fade-in pt-12">
          <p className="text-2xl font-bold">Create and Share Your Gear Lists</p>
          <p className="text-xl font-light max-w-2xl">Keep your pack ultralite by keeping track of what you bring. Create a pack for every adventure.</p>
          <Link to="/signup">
            <button className="bg-neutral-700 text-neutral-100 text-xl px-4 py-2 mt-4">Build Pack</button>
          </Link>
        </div>
      </div>
      <div className="absolute bottom-8 md:bottom-12">
          <Footer />
      </div>
    </div>
  );
}
