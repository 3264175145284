/**
 * @generated SignedSource<<6460e513c9f3a3d93ac1a8299b054992>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type PacksPagesMyPacksQuery$variables = {|
  jwt: string,
|};
export type PacksPagesMyPacksQuery$data = {|
  +myPacks: ?$ReadOnlyArray<?{|
    +metadata: ?{|
      +id: ?string,
      +name: ?string,
    |},
  |}>,
|};
export type PacksPagesMyPacksQuery = {|
  variables: PacksPagesMyPacksQuery$variables,
  response: PacksPagesMyPacksQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "jwt"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "jwt",
        "variableName": "jwt"
      }
    ],
    "concreteType": "Pack",
    "kind": "LinkedField",
    "name": "myPacks",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Metadata",
        "kind": "LinkedField",
        "name": "metadata",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PacksPagesMyPacksQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PacksPagesMyPacksQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6696e3e241c6f4f0f16b66afadda7bd3",
    "id": null,
    "metadata": {},
    "name": "PacksPagesMyPacksQuery",
    "operationKind": "query",
    "text": "query PacksPagesMyPacksQuery(\n  $jwt: String!\n) {\n  myPacks(jwt: $jwt) {\n    metadata {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "8e5472ec18bbf779605b1ae389f4e08e";

module.exports = ((node/*: any*/)/*: Query<
  PacksPagesMyPacksQuery$variables,
  PacksPagesMyPacksQuery$data,
>*/);
