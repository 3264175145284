async function fetchGraphQL(text, variables) {
  const REACT_APP_GRAPHQL = process.env.REACT_APP_GRAPHQL;
  const response = await fetch(REACT_APP_GRAPHQL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: text,
      variables,
    }),
  });
  return await response.json();
}
export default fetchGraphQL;
