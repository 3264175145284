import { SET_USER } from "./actionTypes";

const initialState = {
    accessToken: "",
    uid: "",
}

export default function reducer(state = initialState, action) {
    if (action.type === SET_USER) {
        return { ...state, user: action.user };
    } else {
        return state
    }
}
